import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import {
  getAccount,
  getFactory,
  getProvider,
  getRouter,
  getSigner,
  getAmountOut,
  getBalanceAndSymbol,
  swapTokens,
  getReserves,
} from "../../services/ethereumFunctions";
import { tokensArrayFixed } from "../../services/constants";
import config, { notify } from "../../config/config";
import { Contract } from "ethers";
import Web3 from "web3";

import "../../assets/hypdex.css";
import "../../assets/hypdex-responsive.css";
import { jQueryFunc, loadPage } from "../../assets/js/hypdex.js";
import { withTranslation } from "react-i18next";

const PAIR = require("../../services/IPancakePair.json");
const ERC20 = require("../../services/BEP20.json");

function Swap(props) {
  const { t } = props;

  if (typeof window !== "object") {
    return null;
  }

  const [provider] = useState(getProvider());
  const [signer] = useState(getSigner(provider));
  const [account, setAccount] = useState(undefined); // This is populated in a react hook
  const [router] = useState(getRouter(config.RouterContract, signer));
  /*const [weth, setWeth] = React.useState(
    getWeth(config.WBNB, signer)
  );*/
  const [factory] = useState(getFactory(config.FactoryContract, signer));
  // const [dialog1Open, setDialog1Open] = useState(false);
  // const [dialog2Open, setDialog2Open] = useState(false);
  const [coin1, setCoin1] = useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
  });
  const [coin2, setCoin2] = useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
  });
  const [reserves, setReserves] = useState(["0.0", "0.0"]);
  const [field1Value, setField1Value] = useState("");
  const [field2Value, setField2Value] = useState("");
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const [slippage, setSlippage] = useState(0);
  const [reserveError, setReserveError] = useState(false);

  const switchFields = () => {
    setCoin1(coin2);
    setCoin2(coin1);
    setField1Value(field2Value);
    setReserves(reserves.reverse());
  };

  const handleChange = {
    field1: (e) => {
      setField1Value(e.target.value);
    },
    field2: (e) => {
      setField2Value(e.target.value);
    },
  };

  const formatBalance = (balance, symbol) => {
    if (balance && symbol) return parseFloat(balance).toPrecision(8);
    else return "0.0000";
  };

  const formatReserve = (reserve, symbol) => {
    if (reserve && symbol) return reserve /* + " " + symbol;*/;
    else return "0.0000";
  };

  const isButtonEnabled = () => {
    if (!account) return false;
    let validFloat = new RegExp("^[0-9]*[.]?[0-9]*$");
    return (
      coin1.address &&
      coin2.address &&
      validFloat.test(field1Value) &&
      parseFloat(field1Value) <= coin1.balance &&
      parseFloat(field1Value) <= reserves[0] &&
      slippage < 0.05 &&
      !isNaN(parseFloat(field1Value))
    );
  };

  const validKeyPress = (e) => {
    let validFloat = new RegExp("^[0-9]*[.]?[0-9]*$");
    if (e.keyCode === 8) return;
    if (!validFloat.test(e.key)) {
      e.preventDefault();
    }
  };

  const onToken1Selected = (address) => {
    // setDialog1Open(false);
    if (address === coin2.address) {
      switchFields();
    } else if (address) {
      getBalanceAndSymbol(account, address, provider, signer).then((data) => {
        setCoin1({
          address: address,
          symbol: data.symbol,
          balance: data.balance,
        });
      });
    }
  };

  const onToken2Selected = (address) => {
    // setDialog2Open(false);
    if (address === coin1.address) {
      switchFields();
    } else if (address) {
      getBalanceAndSymbol(account, address, provider, signer).then((data) => {
        setCoin2({
          address: address,
          symbol: data.symbol,
          balance: data.balance,
        });
      });
    }
  };

  const swap = () => {
    if (loading || !isButtonEnabled()) return;
    // console.log("Attempting to swap tokens...");
    setLoading(true);
    swapTokens(
      coin1.address,
      coin2.address,
      parseFloat(field1Value),
      router,
      account,
      signer
    )
      .then(() => {
        setLoading(false);
        setField1Value("");
        notify.success("Transaction successful");
      })
      .catch((e) => {
        setLoading(false);
        notify.error("Transaction Failed (" + e.message + ")");
      });
  };

  const priceImpactColor = () => {
    if (slippage <= 0.01) {
      return "limegreen";
    } else if (slippage <= 0.03) {
      return "inherit";
    } else if (slippage <= 0.05) {
      return "yellow";
    } else {
      return "orangered";
    }
  };

  const routes = async (token1, token2, amountIn) => {
    const pairsCount = await factory.allPairsLength();
    var pairs = [];
    for (var t = 0; t < pairsCount; t++) {
      const pairAddress = await factory.allPairs(t);
      const pancakePair = new Contract(pairAddress, PAIR.abi, signer);
      const token0Contract = new Contract(
        await pancakePair.token0(),
        ERC20.abi,
        signer
      );
      const token1Contract = new Contract(
        await pancakePair.token1(),
        ERC20.abi,
        signer
      );
      const reserves = await pancakePair.getReserves();
      pairs.push({
        contract: pancakePair,
        token0: await pancakePair.token0(),
        symbol0: await token0Contract.symbol(),
        token1: await pancakePair.token1(),
        symbol1: await token1Contract.symbol(),
        reserve0: reserves[0],
        reserve1: reserves[1],
      });
    }
    var bestTrades = [];
    console.log("start", token1, token2);
    bestTrades = await bestTradesExactIn(
      token1,
      token2,
      pairs,
      amountIn,
      [],
      amountIn,
      bestTrades
    );
    console.log("routes:", pairsCount, pairs, bestTrades);
    console.log(
      parseInt(bestTrades[0].amountOut) / 1e18,
      bestTrades[0].route.map((item) => item.symbol0 + "-" + item.symbol1)
    );
  };

  const bestTradesExactIn = async (
    token1,
    token2,
    pairs,
    amountIn,
    currentPairs = [],
    originalAmountIn = amountIn,
    bestTrades = []
  ) => {
    if (pairs.length === 0 || !token1 || !token2) {
      return;
    }
    if (originalAmountIn !== amountIn && currentPairs.length <= 0) {
      return;
    }
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i];
      // pair irrelevant
      if (
        pair.token0.toUpperCase() !== token1.address.toUpperCase() &&
        pair.token1.toUpperCase() !== token1.address.toUpperCase()
      ) {
        continue;
      }
      if (
        pair.token0.toUpperCase() !== token2.address.toUpperCase() &&
        pair.token1.toUpperCase() !== token2.address.toUpperCase()
      ) {
        continue;
      }
      if (pair.reserve0 === 0 || pair.reserve1 === 0) {
        continue;
      }

      let amountOut = 0;
      try {
        amountOut = await router.callStatic.getAmountsOut(
          Web3.utils.toWei(amountIn.toString(), "ether"),
          [pair.token0, pair.token1]
        );
        // ;[amountOut] = await route.getOutputAmount(amountIn)
      } catch (error) {
        // input too low
        console.log("error");
        if (error.isInsufficientInputAmountError) {
          continue;
        }
        throw error;
      }
      // we have arrived at the output token, so this is the final trade of one of the paths
      if (
        pair.token1.toUpperCase() === token2.address.toUpperCase() ||
        pair.token0.toUpperCase() === token2.address.toUpperCase()
      ) {
        bestTrades.push({
          route: [...currentPairs, pair],
          token1: token1,
          token2: token2,
          originalAmountIn: originalAmountIn,
          amountOut: amountOut[1],
          maxNumResults: 0,
        });
        console.log([...currentPairs, pair]);
      } else if (pairs.length > 1) {
        const pairsExcludingThisPair = pairs
          .slice(0, i)
          .concat(pairs.slice(i + 1, pairs.length));

        // otherwise, consider all the other paths that lead from this token as long as we have not exceeded maxHops
        bestTradesExactIn(
          token1,
          token2,
          pairsExcludingThisPair,
          amountOut,
          [...currentPairs, pair],
          originalAmountIn,
          bestTrades
        );
      }
    }
    console.log("bestTrades:", bestTrades);
    return bestTrades;
  };

  useEffect(() => {
    /*
    console.log(
      "Trying to get Reserves between:\n" + coin1.address + "\n" + coin2.address
    );
    */
    if (coin1.address && coin2.address) {
      getReserves(coin1.address, coin2.address, factory, signer, account).then(
        (data) => setReserves(data)
      );
    }
  }, [coin1.address, coin2.address, account, factory, router, signer]);

  useEffect(() => {
    setReserveError(false);
    if (isNaN(parseFloat(field1Value))) {
      setField2Value("");
    } else if (field1Value && coin1.address && coin2.address) {
      if (parseFloat(field1Value) >= parseFloat(reserves[1])) {
        setReserveError(true);
      } else {
        getAmountOut(coin1.address, coin2.address, field1Value, router).then(
          (amount) => {
            if (amount) {
              const price = parseFloat(reserves[0]) / parseFloat(reserves[1]);
              const expectedAmount = parseFloat(field1Value) / price;
              const slippage = Math.abs(
                expectedAmount / (amount === 0 ? 0.001 : amount) - 1
              );
              const expectedPrice = parseFloat(field1Value) / amount;
              // console.log('price:', price, 'expectedAmount:', expectedAmount, 'slippage:', slippage, 'expectedPrice:', expectedPrice)
              setPrice(expectedPrice);
              setSlippage(slippage);
              setField2Value(amount.toFixed(7));
            } else {
              setField2Value("");
            }
          }
        );
      }
    } else {
      setField2Value("");
    }
  }, [field1Value, coin1.address, coin2.address, router, reserves]);

  useEffect(() => {
    const coinTimeout = setTimeout(() => {
      // console.log("Checking balances...");

      if (coin1.address && coin2.address && account) {
        getReserves(
          coin1.address,
          coin2.address,
          factory,
          signer,
          account
        ).then((data) => {
          setReserves(data);
        });
      }
      if (coin1 && account) {
        getBalanceAndSymbol(account, coin1.address, provider, signer).then(
          (data) => {
            setCoin1({
              ...coin1,
              balance: data.balance,
            });
          }
        );
      }
      if (coin2 && account) {
        getBalanceAndSymbol(account, coin2.address, provider, signer).then(
          (data) => {
            setCoin2({
              ...coin2,
              balance: data.balance,
            });
          }
        );
      }
    }, 10000);
    return () => clearTimeout(coinTimeout);
  });

  useEffect(() => {
    jQueryFunc();
    loadPage(window.location.pathname + window.location.search);
    getAccount().then((account) => {
      setAccount(account);
    });
  }, []);

  return (
    <>
      {/*
    <TokenDialog
      open={dialog1Open}
      onClose={onToken1Selected}
      coins={tokensArray}
      signer={signer}
    />
    <TokenDialog
      open={dialog2Open}
      onClose={onToken2Selected}
      coins={tokensArray}
      signer={signer}
    />
    */}
      <section className="page-header p-b-0">
        <div className="page-header-bg"></div>

        <div className="page-header-shape-1"></div>
        <div className="page-header-shape-2"></div>
        <div className="page-header-shape-3"></div>

        <div className="container text-center">
          <div className="page-header__inner">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className=" ">
                  <div className="cube">
                    <div className="s1"></div>
                    <div className="s2"></div>
                    <div className="s3"></div>
                    <div className="s4"></div>
                    <div className="s5"></div>
                    <div className="s6"></div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <h2>TOKEN SWAP</h2>
                <hr />
                <p>
                  {t(
                    "The HyperDex Token Swap enables users to directly swap their tokens via the website. HYP tokens may only be acquired through swapping or earning a HYP reward payout."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hyperdex_cubes">
        <div className="container text-center"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <figure className="hypdex-logo">
                  <div className="cubeHyp">
                    <div className="face face-front"></div>
                    <div className="face face-front-in"></div>
                    <div className="face face-back"></div>
                    <div className="face face-back-in"></div>
                    <div className="face face-left"></div>
                    <div className="face face-left-in"></div>
                    <div className="face face-right"></div>
                    <div className="face face-right-in"></div>
                    <div className="face face-top"></div>
                    <div className="face face-top-in"></div>
                  </div>
                </figure>
                <div className="col-lg-4 col-md-6 col-sm-8 col-xs-12 mx-auto text-center">
                  <h2
                    className=""
                    style={{
                      textAlign: "center",
                      color: "white",
                      marginBottom: "1em",
                      marginTop: "1em",
                    }}
                  >
                    SWAP Coins
                  </h2>
                  <div className="col-md-12 m-t-20">
                    <div>
                      <input
                        type="text"
                        id="field1"
                        className="text-center form-control"
                        placeholder="0.0"
                        name="input_amount"
                        value={field1Value}
                        onKeyDown={validKeyPress}
                        onChange={handleChange.field1}
                      />
                    </div>
                    <select
                      name=""
                      className="thm-btn comment-form__btn padding_select m-t-20 text-center"
                      id=""
                      onChange={(e) =>
                        onToken1Selected(
                          e.target.options[e.target.options.selectedIndex].value
                        )
                      }
                    >
                      <option key="select" value="">
                        {t("Select")}
                      </option>
                      {tokensArrayFixed.map(
                        (item) => console.log(item)
                        // <option key={item.token.t} value={item.token.address}>
                        //   {item.token.name}
                        // </option>
                      )}
                    </select>
                  </div>
                  <h2
                    className="top"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {account ? (
                      <Link
                        to="#"
                        className={
                          loading || !isButtonEnabled() ? "disabled" : ""
                        }
                        onClick={swap}
                      >
                        <b style={{ color: "#ffa0f8" }}>
                          SWAP <i className="fas fa-exchange-alt"></i>
                        </b>
                      </Link>
                    ) : (
                      <p>{t("Connect your wallet to swap")}</p>
                    )}
                  </h2>
                  <div className="col-md-12 m-t-20">
                    <select
                      name=""
                      className="thm-btn comment-form__btn padding_select m-t-20 text-center"
                      id=""
                      onChange={(e) =>
                        onToken2Selected(
                          e.target.options[e.target.options.selectedIndex].value
                        )
                      }
                    >
                      <option key="select" value="">
                        {t("Select")}
                      </option>
                      {tokensArrayFixed.map(
                        (item) => console.log(item)
                        // <option
                        //   key={item.token.name}
                        //   value={item.token.address}
                        // >
                        //   {item.token.name}
                        // </option>
                      )}
                    </select>
                    <div className="m-t-20">
                      <input
                        type="text"
                        id="field2"
                        className="text-center form-control"
                        placeholder="0.0"
                        name="input_amount"
                        value={field2Value}
                        onKeyDown={validKeyPress}
                        onChange={handleChange.field2}
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mx-auto text-center"
                    style={{ height: "120px" }}
                  >
                    {reserveError ? (
                      <h2
                        className="top"
                        style={{
                          textAlign: "center",
                          color: "orangered",
                          margin: "1em 0 1em 0",
                        }}
                      >
                        {t("Not enough reserves")}
                      </h2>
                    ) : (
                      ""
                    )}
                    {price && !reserveError && field1Value ? (
                      <>
                        <div className="row">
                          <div
                            className="col-md-5 col-lg-5"
                            style={{ textAlign: "left" }}
                          >
                            {t("Price")}
                          </div>
                          <div
                            className="col-md-7 col-lg-7"
                            style={{ textAlign: "right" }}
                          >
                            {price.toFixed(7)} {coin1.symbol} per {coin2.symbol}
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-md-5 col-lg-5"
                            style={{ textAlign: "left" }}
                          >
                            {t("Price impact")}
                          </div>
                          <div
                            className="col-md-7 col-lg-7"
                            style={{
                              textAlign: "right",
                              color: priceImpactColor(),
                            }}
                          >
                            {(Math.min(slippage, 1) * 100).toFixed(2)}%
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-md-5 col-lg-5"
                            style={{ textAlign: "left" }}
                          >
                            {t("Liquidity provider fee")}
                          </div>
                          <div
                            className="col-md-7 col-lg-7"
                            style={{ textAlign: "right" }}
                          >
                            {(parseFloat(field1Value) * 0.003).toFixed(2)}{" "}
                            {coin1.symbol}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <h3
                  className="top"
                  style={{
                    textAlign: "center",
                    color: "white",
                    margin: "1em 0 1em 0",
                  }}
                >
                  {t("Your wallet balance")}{" "}
                </h3>
                <div className="col-md-6">
                  <p
                    className="top"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {coin1.symbol || "..."}
                  </p>
                  <p
                    className="top"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {formatBalance(coin1.balance, coin1.symbol)}
                  </p>
                </div>
                <div className="col-md-6">
                  <p
                    className="top"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {coin2.symbol || "..."}
                  </p>
                  <p
                    className="top"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {formatBalance(coin2.balance, coin2.symbol)}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <h3
                  className="top"
                  style={{
                    textAlign: "center",
                    color: "white",
                    margin: "1em 0 1em 0",
                  }}
                >
                  {t("Liquidity pool reserves")}{" "}
                </h3>
                <div className="col-md-6">
                  <p
                    className="top"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {coin1.symbol || "..."}
                  </p>
                  <p
                    className="top"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {formatReserve(reserves[0], coin1.symbol)}
                  </p>
                </div>
                <div className="col-md-6">
                  <p
                    className="top"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {coin2.symbol || "..."}
                  </p>
                  <p
                    className="top"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {formatReserve(reserves[1], coin2.symbol)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withTranslation()(Swap);
