import React from "react";
import { graphql } from "gatsby";
import Swap from "../../modules/swap/index";

const SwapPage = () => <Swap />;

export default SwapPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      nodes {
        data
        ns
        language
      }
    }
  }
`;
